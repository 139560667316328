import $ from 'jquery';

$(window).scroll(function() {    
  var scroll = $(window).scrollTop();

  if (scroll >= 200) {
      $("#navbar").addClass("nav-bg");
  } else {
      $("#navbar").removeClass("nav-bg");
  }
});

var lastScrollTop = 0;
var delta = 5;
var navbarHeight = $('header').outerHeight();

$(window).scroll(function(event){
  
  var st = $(this).scrollTop();
  
  // Make sure they scroll more than delta
  if(Math.abs(lastScrollTop - st) <= delta)
      return;
  
  // If they scrolled down and are past the navbar, add class .nav-up.
  // This is necessary so you never see what is "behind" the navbar.
  if (st > lastScrollTop && st > navbarHeight){
      // Scroll Down
      $('header').removeClass('nav-down').addClass('nav-up');
  } else {
      // Scroll Up
      if(st + $(window).height() < $(document).height()) {
          $('header').removeClass('nav-up');
      }
  }
  
  lastScrollTop = st;
});