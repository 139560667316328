const hamburgerMenu = document.getElementById('hamburger'); 
const mobileNav = document.getElementById('mobile-nav');

hamburger.onclick = function(e) {
    e.preventDefault();
    mobileNav.classList.toggle('open');
    this.classList.toggle('open');
    document.body.classList.toggle('fixed');

};

