import $ from 'jquery';

var sidebarLinks = $('.about-anchor a');

sidebarLinks.on('click', function(e)
{
    e.preventDefault();
    var target = $(this.hash);
    var lastScrollTop = 0;

    $('html, body').animate({
        'scrollTop': target.offset().top - 0
    }, 400);
});