import flickity from 'flickity';

const SliderHandlerRegular = () =>
{
    const slider = document.querySelectorAll('.js-slider');

    let groupCells = false;

    if ( slider != undefined ) {
        for (let i = 0; i < slider.length; i++) {
            let flkty = new flickity(slider[i], {
                cellAlign: 'left',
                pageDots: true,
                prevNextButtons: true,
                wrapAround: true,
                selectedAttraction: 0.01,
                friction: 0.15,
                groupCells: groupCells,
            });

        }
    }
}

export default SliderHandlerRegular;