// Use Browserify to load jQuery etc!
// Ask in #dev if you don't know how.
import $ from 'jquery';
import SliderHandlerRegular from './regularSlider';
import './navbarScroll';
import './pageScroll';
import './hamburgerMenu';

$(document).ready(function () {
    SliderHandlerRegular();
});